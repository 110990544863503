import * as React from "react"
import MainContainer from "../components/MainContainer"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Title from "../components/misc/Title"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/SEO"

const ErrorImageStyles = css({
    flexBasis: "25%",
    "@media(max-width: 800px)": {
        flexBasis: "35%"
    },
    "@media(max-width: 600px)": {
        flexBasis: "45%"
    },
    "@media(max-width: 420px)": {
        flexBasis: "60%"
    }
})

const BasicLink = styled(Link)({
    color: "#111",
    textDecoration: "underline"
})

const Wrapper = styled.div({
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    marginRight: "10%",
    marginLeft: "10%",
    marginTop: "5em",
    marginBottom: "20em",
    backgroundColor: "rgba(240, 240, 240, 0.95)",
    borderRadius: "0.7em",
    padding: "1em",
    paddingTop: "2em",
    paddingBottom: "2em",
    border: "1px solid #ccc",
    boxShadow: "0 0 4px #000"
})

const ErrorParagraph = styled.p({
    textAlign: "justify",
    fontSize: "1.5em",
    fontWeight: "500",
    lineHeight: "1.35em",
    paddingLeft: "1em",
    paddingRight: "1em"
})

const Box = styled.div({
    flexBasis: "50%",
    "@media(max-width: 1115px)": {
        flexBasis: "80%"
    }
})

export default function NotFoundPage() {

    const { seoData } = useStaticQuery(query)
    const { seoTitle, seoDescription } = seoData

    return (
        <MainContainer>
            <SEO title={seoTitle} description={seoDescription} pagename="" />
            <Wrapper>
                <StaticImage src="../assets/images/404.svg" alt="Error 404" css={ErrorImageStyles} />
                <Box>
                    <Title>Página no encontrada</Title>
                    <ErrorParagraph>
                        Lo sentimos, no hemos encontrado la página. Pruebe con otra dirección o
                        vuelva a la <BasicLink to="/">página principal</BasicLink>
                    </ErrorParagraph>
                </Box>
            </Wrapper>
        </MainContainer>
    )
}

const query = graphql`
    query {
        seoData: headersJson(id: {eq: "404"}) {
            seoTitle
            seoDescription
        }
    }
`
